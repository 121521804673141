<template>
  <div id="plantilla" class="contenedor">
    <loader :show="show" />
    <sesion :expiration="expiration" />
    <Sidebar></Sidebar>
    <v-container fluid class="base text-center">
      <v-row no-gutters class="titulo ml-10 mt-5">INCORPORACIÓN DE EMPLEADOS</v-row>
      <v-row no-gutters justify="end" class="mr-12 mt-n5">
        <v-col cols="12" lg="6" md="6" sm="10" xs="12">
          <v-card-title>
            <v-text-field
              class="mr-2"
              v-model="search"
              append-icon="search"
              label="Buscador"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
        </v-col>
      </v-row>
      <v-row no-gutters class="datos pa-0 mt-1" style="height: auto !important">
        <v-card style="width: 100%; background: rgb(199, 195, 199) !important">
          <v-data-table
            :headers="headers"
            :items="employees"
            :search="search"
            class="elevation-2 text--dark"
            loading-text="Cargando datos... Por favor espere"
            :items-per-page="5"
            :no-results-text="'No se encontraron incidencias'"
            :no-data-text="'No hay datos'"
            :footer-props="{
              'items-per-page-text': 'Empleados por página',
              'items-per-page': [5, 10, 15],
              'items-per-page-all-text': 'Todos',
              'items-per-page-options': [5, 10],
              'loading-text': 'Obteniendo datos... Por favor espere',
              'no-data-text': 'No hay datos...',
              'no-results-text': 'No se encontraron incidencias',
            }"
          >
            <template v-slot:no-data>
              <v-alert :value="true" color="#ffffff" icon="warning">
                <p style="color: black">No hay datos en sistema ...</p>
              </v-alert>
            </template>
            <template v-slot:body="{ items }">
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.empleadoId"
                  :class="{ selectedRow: item === selectedItem }"
                >
                  <td class="text-sm-center">{{ item.empleadoId }}</td>
                  <td class="text-sm-center">{{ item.nombre }}</td>
                  <td class="text-sm-center">{{ item.fechaIngreso }}</td>
                  <td class="text-sm-center">{{ item.puesto }}</td>
                  <td class="text-sm-center">{{ item.area }}</td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-row>
    </v-container>
  </div>
</template>
<script>
import Sidebar from "@/components/Sidebar.vue";
import Loader from "@/components/Loader";
import Sesion from "@/components/Sesion";
export default {
  components: {
    Sidebar,
    Loader,
    Sesion,
  },
  data() {
    return {
      show: false,
      expiration: false,
      search: "",
      selectedItem: null,
      headers: [
        {
          text: "Num. Empleado",
          align: "center",
          value: "empleadoId",
        },
        {
          text: "Nombre",
          align: "center",
          value: "nombre",
        },
        {
          text: "Fecha Ingreso",
          align: "center",
          value: "fechaIngreso",
        },
        {
          text: "Cargo",
          align: "center",
          value: "puesto",
        },
        {
          text: "Departamento/Área",
          align: "center",
          value: "area",
        },
      ],
      employees: [],
      enterprise:localStorage.empresaIdGlobal
    };
  },
  methods: {
    listar() {
      this.show = true;
      axios
        .get(Server + "/dashboard/inventario-alta-personal/" + this.enterprise, {
          headers: {
            Authorization: localStorage.token,
          },
        })
        .then((response) => {
          this.employees = response.data;
          this.show = false;
        })
        .catch((e) => {
          console.log(e);
          this.show = false;
          if (e.response.status == 401) {
            this.expiration = true;
          }
        });
    },
  },
  created() {
    this.listar();
  },
};
</script>